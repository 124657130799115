import { getKLines } from '@/binance';
import MaTable from '@/components/ma-table';
import PostTemplate from '@/templates/post';
import { DataFrame, defaultDataFrame, PostTemplateProps } from '@/types';
import { sma } from '@/utils/panda';
import { useState, useEffect } from 'react';
import { Articles } from '../../articles';
import { ChartLine } from '@/components/charts/ChartLine';
import CryptoPlot from '@/components/charts/crypto-plot';

export default function Xxx50And200DayMaChartTemplate({
  location,
  articleId,
  coinAbbreviation,
  coinFullName,
  token,
}: PostTemplateProps) {
  const [dataFrame, setDataFrame] = useState<DataFrame>(defaultDataFrame);
  const [ma50, setMa50] = useState<number[]>();
  const [ma200, setMa200] = useState<number[]>();

  const [ma50Line, setMa50Line] = useState<ChartLine>({
    y: [],
    color: 'red',
    title: 'MA50',
  });

  const [ma200Line, setMa200Line] = useState<ChartLine>({
    y: [],
    color: 'blue',
    title: 'MA200',
  });

  useEffect(() => {
    getKLines(token, '1d', 1000).then(function (df: DataFrame) {
      setDataFrame(df);
      const sma50 = sma(df.close, 50);
      const sma200 = sma(df.close, 200);
      setMa50(sma50);
      setMa200(sma200);

      setMa50Line({
        color: 'red',
        title: 'MA50',
        y: sma50,
      });

      setMa200Line({
        color: 'blue',
        title: 'MA200',
        y: sma200,
      });
    });
  }, [token]);

  return (
    <PostTemplate
      location={location}
      keywords={[coinFullName.toLowerCase(), 'ma50', 'ma200', 'chart']}
      articleId={articleId}
    >
      <section>
        <p>
          Whether you are using the {coinAbbreviation} 50-day or 200-day moving
          average chart, the method of calculation and the manner in which the
          moving average is interpreted remain the same.
        </p>
        <CryptoPlot
          data={dataFrame}
          lines={[ma50Line, ma200Line]}
          title={Articles[articleId].title}
          description={Articles[articleId].description}
        />
        <p>
          The {coinFullName} moving average chart simply represents an
          arithmetic mean of a certain number of {coinAbbreviation} closing
          prices. The only difference between the {coinAbbreviation} 50-day
          moving average chart and the {coinAbbreviation} 200-day moving average
          chart is the number of time periods used in the plotting. The{' '}
          {coinAbbreviation} 50-day moving average is calculated by summing up
          the past 50 {coinAbbreviation} closing prices and then dividing the
          result by 50, while the {coinAbbreviation} 200-day moving average is
          calculated by summing the past 200 days and dividing the result by
          200.
        </p>
        <p>
          As the question implies, many technical crypto traders use these
          averages to aid in choosing where to enter or exit a position, which
          then causes these levels to act as strong support or resistance.
        </p>
        <p>
          Moving averages (MA) are often viewed as a low-risk area to place
          transactions since they correspond to the {coinAbbreviation} average
          price that all crypto traders have paid over a given time frame. For
          example, the {coinAbbreviation} 50-day moving average is equal to the
          average price that all investors have paid to obtain the asset over
          the past 10 trading weeks (or two and a half months), making it a
          commonly used support level.
        </p>
        <MaTable
          header1="Date"
          header2={'Closing Price (' + coinAbbreviation + ')'}
          header3={'50-Day Moving Average (' + coinAbbreviation + ')'}
          dates={dataFrame.date}
          close={dataFrame.close}
          ma={ma50}
          numberOfRows={10}
        />
        <p>
          Similarly, the {coinFullName} 200-day moving average chart represents
          the {coinAbbreviation} average price over the past 40 weeks, which is
          used to suggest a relatively cheap price compared to the price range
          over most of the past year. Once the price falls below this average,
          it may act as resistance because individuals who have already taken a
          position may consider closing the position to ensure that they do not
          suffer a large loss.
        </p>
        <MaTable
          header1="Date"
          header2={'Closing Price (' + coinAbbreviation + ')'}
          header3={'50-Day Moving Average (' + coinAbbreviation + ')'}
          dates={dataFrame.date}
          close={dataFrame.close}
          ma={ma200}
          numberOfRows={10}
        />
        <p>
          Critics of technical analysis say that {coinFullName} moving average
          charts help to understand support and resistance because so many
          crypto traders use these indicators to inform their trading decisions.
        </p>
      </section>
    </PostTemplate>
  );
}
